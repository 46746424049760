<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="loaded"
      class="page"
    >
      <ul class="contact-links">
        <li>
          <a
            href="https://www.linkedin.com/in/nicole-barrance/"
            target="_blank"
          >
            LinkedIn
          </a>
        </li>
        <li>nicolebarrance@gmail.com</li>
      </ul>
    </div>
  </transition>
</template>

<script>
export default {
    name: 'Contact',
    data() {
        return {
            loaded: false,
        }
    },
    created() {
        setTimeout(()=> {
            this.loaded = true
        }, 100)
    }
}
</script>

<style lang="scss">
.page {

    .contact-links {
        font-family: $magilio;
        margin: 0;
        list-style-type: none;
        text-align: center;
        font-size: 16px;
        line-height: 12px;
        color: var(--text-color);
        width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;

        > li:not(:first-of-type) {
            margin-top: 6px;
        }
    }
}
</style>

import daisy_01 from '@/assets/mouse-trail/daisy_01.png'
import daisy_02 from '@/assets/mouse-trail/daisy_02.png'
import daisy_03 from '@/assets/mouse-trail/daisy_03.png'

export const mouseTrail = {
    data() {
        return {
            wait: false,
            images: [
                daisy_01,
                daisy_02,
                daisy_03
            ]
        }
    },
    computed: {
        styleHandler() {
            return 'position: fixed; pointer-events: none; opacity: 1;'
        }
    },
    methods: {
        randomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min)
        },
        mouseTrail(e) {
            if (!this.wait) {
                let X = e.clientX
                let Y = e.clientY
                let elWrap = document.createElement('div')
                let el = document.createElement('img')
                elWrap.appendChild(el)
                elWrap.setAttribute('style', this.styleHandler)
                elWrap.style.transition = 'all 1s linear'
                el.style.transition = 'all 1s linear'
                elWrap.style.left = X + 'px'
                elWrap.style.top = Y + 'px'
                el.src = this.images[this.randomInt(0, this.images.length - 1)]

                el.style.height = this.randomInt(20,40) + 'px'



                // console.log(el)
                document.body.appendChild(elWrap)
                el.classList.add('__fadeIn')
                this.wait = true

                window.setTimeout(() => {
                    document.body.removeChild(elWrap)
                }, 1000)
                window.setTimeout(() => {
                    elWrap.style.opacity = '0';
                    elWrap.style.transform = `translate(${this.randomInt(-100, 100)}px, ${this.randomInt(20, 200)}px)`
                    el.style.transform = `rotate(${this.randomInt(0, 360)}deg)`
                    this.wait = false
                }, 40)
            }

        }
    }
}


export default mouseTrail;
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Vue3Mq } from "vue3-mq";

// import { GraphQLClient } from "graphql-request";
//
// const graphcmsClient = new GraphQLClient(
//     'https://api-eu-central-1.graphcms.com/v2/ckvzb41ab44a101wc92ar2oyx/master'
// )

let app = createApp(App)

app.config.globalProperties.endpoint = 'https://api-eu-central-1.graphcms.com/v2/ckvzb41ab44a101wc92ar2oyx/master';
app.use(Vue3Mq,{
    preset: 'devices'
})
app.use(router).mount('#app')


// createApp(App).use(router).mount('#app')


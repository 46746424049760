<template>
  <transition name="fade">
    <ul
      v-if="loaded"
      class="work-list"
    >
      <li
        v-for="item in workList"
        :key="item.id"
      >
        <a
          :href="item.url"
          target="_blank"
          rel="external"
        >
          <b>{{ item.title }}</b>
          <i style="display: block;">{{ item.publication }}</i>
        </a>
      </li>
    </ul>

    <Loader
      v-else
      fill="#3D3F3E"
    />
  </transition>
</template>

<script>
import { request, gql } from 'graphql-request'
import Loader from '../components/Loader'

export default {
    name: 'Work',
    components: {
        Loader
    },
    data() {
        return {
            loaded: false,
            workList: []
        }
    },
    created() {
        this.fetchWorkList()
    },
    methods: {
        async fetchWorkList() {
            const data = await request(this.endpoint,
                gql`
                    {
                        publishedItems(orderBy: createdAt_DESC) {
                            id
                            title
                            publication
                            url
                        }
                    }
                `
            )

            this.workList = data.publishedItems
            this.loaded = true
        }
    }
}
</script>

<style lang="scss">
.work-list {
    font-family: $jetbrains;
    font-size: 12px;
    font-weight: normal;
    padding: 0 0 0 20px;
    color: #3D3F3E;
    transition: color 0.2s;
    list-style-type: "¤";
    margin: 20px 0 0;

    @include mq('tablet') {
        margin-top: 40px;
    }

    > li {
        padding-left: 6px;
    }

    > li:not(:first-of-type) {
        margin-top: 10px;
    }

    > li:hover {
        color: darken(#3D3F3E, 12%);
        text-decoration: line-through;
    }
}
</style>
<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="loaded"
      class="post"
    >
      <h1 class="post-title">
        {{ post.title }}
      </h1>
      <p class="post-date">
        {{ formatMonth }}
      </p>
      <div class="post-image">
        <img
          v-if="post.coverImage"
          :src="post.coverImage.url"
          height="200"
          alt="cover image"
        >
      </div>
      <div
        class="post-body"
        v-html="post.content.html"
      />
      <div class="post-links">
        <div
          class="link"
          :style="!nextPost && hidden"
        >
          <p>Next</p>
          <router-link
            v-if="nextPost"
            :to="/post/ + nextPost.slug"
          >
            {{ nextPost.title }}
          </router-link>
        </div>

        <div
          class="link"
          :style="!previousPost && hidden"
        >
          <p>Previous</p>
          <router-link
            v-if="previousPost"
            :to="/post/ + previousPost.slug"
          >
            {{ previousPost.title }}
          </router-link>
        </div>
      </div>
    </div>

    <Loader
      v-else
      fill="#3D3F3E"
    />
  </transition>
</template>

<script>
import {gql, request} from "graphql-request";
import Loader from '../components/Loader'

export default {
    name: 'Post',
    components: {
        Loader
    },
    data() {
        return {
            post: null,
            loaded: false,
            previousPost: [],
            nextPost: []
        }
    },
    computed: {
        formatMonth() {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            let date = this.post.date.split("-")
            return `${Number(date[2])} ${months[Number(date[1]) - 1]} ${date[0]}`
        },
        hidden() {
            return {
                'visibility': 'hidden'
            }
        }
    },
    watch: {
        $route(to) {
            // Watch for route changes on Post page
            // to refresh page content
            if (to.name === 'Post') {
                this.fetchPost(this.$route.params.slug)
                this.fetchSiblingPosts()
            }
        }
    },
    created() {
        this.fetchPost(this.$route.params.slug)
        this.fetchSiblingPosts()
    },
    methods: {
        async fetchPost(slug) {
            this.loaded = false;

            const query = gql`
                query getPost($slug: String!) {
                    post(where: {slug: $slug}) {
                        id
                        title
                        date
                        coverImage {
                            url
                        }
                        content {
                            text
                            html
                        }
                    }
                }
            `

            const data = await request(this.endpoint, query, { slug: slug })

            this.post = data.post
            this.loaded = true;
        },
        async fetchSiblingPosts() {
            // query all posts
            const query = await request(this.endpoint, gql` {
                posts {
                    slug
                    title
                }
            }`)

            // find index of current post by slug
            let index = query.posts.findIndex(p => p.slug === this.$route.params.slug)
            // update empty prev/next props based on current post index
            this.nextPost = query.posts[index - 1];
            this.previousPost = query.posts[index + 1];
        }
    }
}
</script>

<style lang="scss">
.post {
    margin-bottom: 80px;
    @include mq('tablet') {
        margin-bottom: 120px;
    }

    .post-title {
        font-family: $magilio;
        font-size: min(10vw, 32px);
        text-align: center;
        color: var(--text-color);
        margin-bottom: 20px;
        font-weight: normal;
        @include mq('tablet') {
            text-align: left;
        }
    }

    .post-date {
        font-family: $jetbrains;
        font-weight: bold;
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        color: var(--text-color);
        @include mq('tablet') {
            text-align: left;
        }
    }

    .post-image {
        display: flex;
        margin-top: 40px;

        img {
            max-width: 240px;
            object-fit: contain;
            margin: auto;
            width: 100%;
            @include mq('tablet') {
                max-width: unset;
            }
        }
    }

    .post-body {
        margin-top: 50px;
        font-family: $jetbrains;
        font-size: 12px;
        line-height: 20px;
        color: #3E3F3E;

        ul {
            margin: 10px 0;
            padding-left: 20px;
        }
    }

    .post-links {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        grid-row-gap: 20px;
        @include mq('tablet') {
            flex-direction: row-reverse;
            justify-content: space-between;
        }

        .link:first-of-type {
            > p,
            > a {
                text-align: right;
            }
        }

        .link {
            > p,
            > a {
                display: block;
            }

            > p {
                font-family: $magilio;
                font-size: 16px;
                font-weight: bold;
                color: #3E3F3E;
                margin-bottom: 3px;
            }

            > a {
                font-family: $jetbrains;
                font-weight: bold;
                font-size: 12px;
                line-height: 20px;
                color: var(--text-color);
            }
        }
    }
}
</style>

import { createWebHistory, createRouter } from 'vue-router';
// import Home from '../pages/Home';
import Contact from '../pages/Contact';
import Contents from '../pages/Contents';
import Post from '../pages/Post';
import Work from '../pages/Work';

const routes = [
    {
        path: '/',
        // redirect: '/journal'
        name: 'Home',
        component: () => import('@/pages/Home')
    },
    {
        path: '/journal',
        name: 'Contents',
        component: Contents
    },
    {
        path: '/work',
        name: 'Work',
        component: Work
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    // {
    //     path: '/contents',
    //     name: 'Contents',
    //     component: Contents
    // },
    {
        path: '/post/:slug',
        name: 'Post',
        component: Post
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;

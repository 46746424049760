<template>
  <nav
    class="navigation"
    :class="minimizeNav && '__minimized'"
  >
    <router-link
      to="/"
      class="nav-title"
    >
      <h1>Nicole</h1>
      <h1>Barrance</h1>
    </router-link>


    <router-link
      v-if="minimizeNav"
      to="/journal"
      class="back-arrow-icon"
    >
      <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.4287 13.7216L0.341671 7.67216C-0.03186 7.30094 -0.03186 6.69907 0.341671 6.32784L6.4287 0.278418C6.80223 -0.0928058 7.40784 -0.0928058 7.78137 0.278418C8.1549 0.64964 8.15491 1.25151 7.78137 1.62273L2.37068 7L7.78138 12.3773C8.15491 12.7485 8.15491 13.3504 7.78138 13.7216C7.40784 14.0928 6.80223 14.0928 6.4287 13.7216Z"
          fill="var(--text-color)"
        />
      </svg>
    </router-link>

    <ul class="nav-links">
      <!--            <li><router-link to="/journal">Journal</router-link></li>-->
      <li :class="$route.name === 'Work' && 'active'">
        <router-link to="/work">
          Work
        </router-link>
      </li>
      <li class="daisy">
        <router-link
          to="/"
          @mouseenter="startRotate()"
          @mouseleave="stopRotate()"
        >
          <img
            :style="rotationProp"
            src="../assets/daisy.svg"
            alt="daisy flower"
          >
        </router-link>
      </li>
      <li :class="$route.name === 'Contact' && 'active'">
        <router-link to="/contact">
          Contact
        </router-link>
      </li>
    </ul>

    <transition name="fade">
      <div
        v-if="minimizeNav"
        class="transition-overlay"
      />
    </transition>
  </nav>
</template>

<script>
export default {
    name: 'Navigation',
    data() {
        return {
            interval: null,
            rotating: false,
            degrees: 0
        }
    },
    computed: {
        rotationProp() {
            return [
                `transform: rotate(${this.degrees}deg);`
            ]
        },
        minimizeNav() {
            return !!this.$route.path.includes('/post/')
        }
    },
    methods: {
        startRotate() {
            this.rotating = true;
            if (this.rotating) {
                this.interval = setInterval(()=> {
                    this.degrees = this.degrees + 1;
                }, 15)
            }
        },
        stopRotate() {
            this.rotating = false;
            clearInterval(this.interval)
        }
    }
}
</script>

<style lang="scss">

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
    height: max-content;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
    height: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transform: translateY(0);
    transition: transform 1s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
    transform: translateY(100%);

}

.navigation {
    font-family: $magilio;
    position: relative;
    padding: 24px 20px 0;
    color: var(--text-color);
    overflow: hidden;
    margin: auto;

    .back-arrow-icon {
        position: absolute;
        z-index: 1;
        top: 32px;
        opacity: 0;
        animation: fade-in 0.5s ease forwards;
        animation-delay: 0.5s;
    }

    .nav-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        letter-spacing: 1px;

        h1 {
            font-size: 2em;
            font-weight: bold;
        }
    }

    .nav-links {
        margin: 0 0 30px 0;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        display: flex;
        grid-column-gap: 40px;
        font-size: 18px;
        font-weight: bold;
        transition: margin 0.3s ease;
        transition-delay: 0s !important;

        > li:last-of-type,
        > li:first-of-type {
            width: 100%;
            max-width: 200px;
        }

        > li:first-of-type {
            text-align: right;
        }

        > li.active {
            text-decoration: line-through;
        }

        .daisy {
            z-index: 1;

            > a {
                display: flex;
            }
        }
    }

    .transition-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: var(--background-color);
        top: 0;
        left: 0;
    }
}

.navigation.__minimized {
    .nav-links {
        margin-top: -50px;
        transition-delay: 0.2s !important;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>

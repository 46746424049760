<template>
  <transition
    name="fade-delay"
    mode="out-in"
  >
    <ul
      v-if="loaded"
      class="contents-list"
    >
      <li
        v-for="post in posts"
        :key="post.id"
      >
        <router-link :to="/post/ + post.slug">
          {{ post.title }}
        </router-link>
      </li>
    </ul>

    <Loader
      v-else
      fill="#3D3F3E"
    />
  </transition>
</template>

<script>
import { request, gql } from 'graphql-request'
import Loader from '../components/Loader'

export default {
    name: 'Contents',
    components: {
        Loader
    },
    data() {
        return {
            loaded: false,
            value: '',
            posts: []
        }
    },
    created() {
        this.fetchPosts()
    },
    methods: {
        async fetchPosts() {
            const endpoint = 'https://api-eu-central-1.graphcms.com/v2/ckvzb41ab44a101wc92ar2oyx/master'

            const data = await request(endpoint,
                gql`
                    {
                        posts(orderBy: createdAt_DESC) {
                            id
                            title
                            date
                            slug
                        }
                    }
                `
            )

            this.posts = data.posts
            this.loaded = true;
        }
    }
}
</script>

<style lang="scss">
.contents-list {
    font-family: $jetbrains;
    font-size: 12px;
    font-weight: normal;
    padding: 0 0 0 20px;
    margin: 0;
    color: #3D3F3E;
    @include mq('tablet') {
        margin-top: 40px;
    }

    > li:not(:first-of-type) {
        margin-top: 8px;
    }
}
</style>

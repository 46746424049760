<template>
  <div
    id="main-container"
    @mousemove="mouseTrail($event)"
  >
    <div class="wrapper">
      <navigation />
      <main>
        <vue-page-transition name="fade-in-right">
          <router-view v-slot="{ Component }">
            <keep-alive include="Contents">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </vue-page-transition>
      </main>
    </div>
  </div>
</template>

<script>
import Navigation from './components/Navigation';
import mouseTrail from "@/mixins/mouseTrail";

export default {
    name: 'App',
    components: {
        Navigation
    },
    mixins: [mouseTrail],
    data() {
        return {
            loaded: false,
            backgroundColor: '',
            colors: [
                { background: '#FFF6D8', text: '#244F4B' },
                { background: '#BBC4F7', text: '#EA7B7A' },
                { background: '#A27981', text: '#FAEBD7' },
                { background: '#FCD1AD', text: '#E95A79' },
                { background: '#A0CBD0', text: '#6F4FE0' }
            ],
            count: 0
        };
    },
    watch: {
        $route() {
            this.setTheme();
        }
    },
    mounted() {
        setTimeout(() => {
            this.loaded = true;
        }, 100);
    },
    methods: {
        setTheme() {
            const html = document.querySelector('html');
            if (this.count < this.colors.length) {
                html.style.cssText = `--background-color: ${this.colors[this.count].background}; --text-color: ${this.colors[this.count].text}`;
                this.count++;
            } else if (this.count === this.colors.length) {
                html.style.cssText = `--background-color: ${this.colors[0].background}; --text-color: ${this.colors[0].text}`;
                this.count = 1;
            }
        }
    }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400');

@font-face {
    font-family: 'Magilio';
    src: url('./fonts/magilio-font/Magilio-Regular.woff') format('woff'),
    url('./fonts/magilio-font/Magilio-Regular.woff2') format('woff2');
}

.slow-fade-enter-active,
.slow-fade-leave-active {
    transition: opacity 5s ease-in;
}

.slow-fade-enter-from,
.slow-fade-leave-to {
    opacity: 0;
}

// base styles
#main-container > .wrapper {
    max-width: $max-width;
    margin: auto;
    height: 100%;
    position: relative;
}

* {
    box-sizing: border-box;
}

html, body, #app, #main-container {
    height: 100%;
}

html {
    background: var(--background-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
}

body {
    margin: 0;
}

#app {
    //overflow: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

main {
    padding: 20px;
    margin: auto;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    line-height: normal;
}

ul {
    margin: 0;
    padding: 0;
}

// mouseTrail.js related styles
.__fadeIn {
    animation: fff forwards 0.2s ease-in;
}
@keyframes fff {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

</style>
